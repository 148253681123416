
.box-login-text1{
    font-family: 'inter-regular';
 

    line-height: 15px;
    margin: 20px 170px -10px 0px;
}

.bluebox-text1{
    color: #122B45;

}