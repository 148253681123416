.add-sahay-year .MuiInputBase-root .MuiOutlinedInput-input {
  height: 10px;

}
.common-button .add-shaya-year-btn {
  margin-top: -3px;
}
.sahay-table .table-design-main tr td {
  padding: 5px 22px !important
}

.sahay-table .adjust-list-table tr td {
  padding: 8px 22px !important
}