/* .admin-rolelist-row {
    margin-top: 20px;
} */

.role-total-user-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.role-total-user-flex .role-totle-user-text {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
    font-family: 'poppins-semibold';
    margin-bottom: 6px;
    color: #122B45;
    margin-top: 0px;
}


.role-user-name-box .role-user-name {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0px;
    font-family: 'poppins-regular';
    margin-bottom: 0px;
    text-transform: capitalize;
    color: #122b45;
}

.edit-role-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px;
}

.edit-role-flex .edit-role-text {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0px;
    font-family: 'poppins-regular';
    margin-bottom: 6px;
    color: #0061ff;
    margin-top: 0px;
    margin-bottom: 0px;
}

.role-user-img-flex .user-avtar-group-main .role-user-imges {
    border: 1px solid #fff;
    height: 25px;
    width: 25px;
}

.role-user-imges .MuiAvatar-img {
    height: 25px;
    width: 25px;
    object-fit: contain;
}

.role-user-name-box {
    margin: 5px 0px;
}
.user-avtar-group-main .MuiAvatar-circular {
    height: 25px;
    border: 1px solid #fff !important;
    width: 25px;
    font-size: 10px;
    line-height: 17px;
    font-family: 'poppins-light';
    background-color: #003d90;
    margin-left: -7px !important;
}

.edit-role-icon {
    height: 20px;
    width: 20px;
    object-fit: contain;
    cursor: pointer;
}

.add-role-more-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.role-more-img {
    height: 100px;
    width: 100px;
    object-fit: contain;
}


.role-titles-main .role-titles {
    font-size: 14px;
    line-height: 21px;
    font-family: "Poppins-Regular";
    margin-bottom: 0px;
    color: #122b45;
}

.roles-data-box {
    max-width: 100px;
    flex: 0 0 100px;
    width: 100%;
}

.add-role-flex {
    display: flex;
}

.roles-data-box .role-data-title {
    font-size: 13px;
    line-height: 19px;
    color: #122b45;
    font-family: 'poppins-semibold';
}

.role-card-inner-flex {
    display: flex;
}

.roles-data-main-flex {
    max-width: 30%;
    width: 100%;
    flex: 0 0 30%;
    display: flex;
    justify-content: flex-end;
}

.role-titles-main {
    max-width: 70%;
    width: 100%;
    flex: 0 0 70%;
}

.roles-data-box .check-box-role svg {
    color: #122b45;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.roles-data-box .check-box-role {
    padding: 0px;
}

.role-card-main {
    width: 100%;
    background-color: #a6ccff;
    border-radius: 11px;
    padding: 15px;
}

.role-list-check {
    margin-top: 50px;
}

.add-game-btn {
    display: flex;
    justify-content: end;
    margin: 0px -5px;
}

.add-game-btn button {
    margin: 0px 5px;
}