@media screen and (min-width: 1600px) and (max-width: 1919px) {
}

@media screen and (min-width: 1366px) and (max-width: 1599px) {
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {
}

@media screen and (min-width: 992px) and (max-width: 1279px) {
  .role-card-main .role-total-user-flex .role-totle-user-text {
    font-size: 12px;
    line-height: 16px;
    margin-right: 5px;
  }

  .custom-res-set-search-mb {
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .display-row-userlist .role-card-main {
    padding: 9px;
  }

  .select-view {
    margin-top: 20px;
  }
}

@media screen and (max-width: 991px) {
  /* .admin-dashboard-box {
    width: 100%;
  } */
  .custom-res-set-search-mb {
    flex-wrap: wrap;
    row-gap: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .text-btn-end-ipad {
    justify-content: end;
  }

  .res-set-search-add {
    justify-content: end;
  }

  .user-sidebar-main {
    display: none;
  }

  .user-apply-content {
    padding: 30px 15px;
  }
}

@media (max-width: 767px) {
  .apply-now button {
    max-width: 110px;
  }

  .res-set-search-add {
    justify-content: end;
  }

  .white-login-main {
    padding: 0px 15px;
  }

  .white-login-box.MuiBox-root.css-0 {
    padding: 50px 25px;
    width: 100%;
  }

  .main-box {
    max-width: 100% !important;
    width: 100%;
    flex: 0 0 100%;
  }

  .orange-btn button,
  .orange-btn button:hover,
  .orange-btn .buttons,
  .orange-btn .buttons:hover {
    width: 100%;
    min-width: initial;
  }

  .res-set-search {
    width: 100%;
    justify-content: end;
  }

  .custom-space-details {
    justify-content: end;
  }

  .custom-space-details.save-btn button {
    margin-left: 0px;
  }

  .res-set-search .search {
    width: 100% !important;
  }

  .remove-btn {
    text-align: end;
  }

  .res-profile-mobile {
    padding: 0px;
  }

  .user-sidebar-main {
    display: none;
  }

  .user-admin-panel-content {
    padding: 20px 15px;
  }

  .logout-profile {
    font-size: initial !important;
  }

  .user-apply-content {
    padding: 30px 15px;
  }

  .lable-input-grp {
    margin-right: 0px;
  }

  .select-view {
    margin-top: 0px;
  }

  .trust-main-div .mb-10px {
    margin-bottom: 10px;
  }

  .select-view-document-name {
    margin-top: 0px;
  }

  .mobile-menu-hidden {
    display: none;
  }

  .res-mobile-show {
    display: block;
  }

  .apply-sahay-btn svg {
    margin-right: 0px;
  }

  .res-mobile-show.blue-button button,
  .res-mobile-show.blue-button button:hover {
    min-width: max-content;
  }

  .desk-hidden-show {
    display: none;
  }

  .dashboard-text p,
  .title-main .page-title {
    font-size: 15px;
    line-height: 24px;
  }

  .roles-data-main-flex {
    max-width: 100% !important;
    width: 100% !important;
    flex: 0 0 100% !important;
    display: flex;
    justify-content: space-between !important;
    margin-top: 10px !important;
  }

  .roles-data-main-flex .roles-data-box {
    max-width: initial;
    flex: initial;
    width: initial;
  }

  .role-card-inner-flex {
    display: flex;
    flex-direction: column;
  }

  .search {
    margin-right: 0px !important;
  }

  .plus-hidden-show-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .admin-tabs-main .admin-tab {
    min-height: 30px;
  }

  .multiple-amount-text {
    margin: 0px 0px 15px;
  }
}

@media screen and (min-width: 280px) and (max-width: 320px) {
  .custom-input-dibursement {
    max-width: 100%;
  }
}
