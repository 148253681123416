.input-design-div .form-multiple-dropdowns {
    width: 100%;
}

.input-design-div .form-multiple-dropdowns .select-multiple-dropdowns .MuiSelect-select {
    height: 24.03px;
    line-height: 28px;
    color: #122B45;
    font-family: 'inter-regular';
    font-size: 14px;
    padding-right: 30px !important;
}

.input-design-div .form-multiple-dropdowns .select-multiple-dropdowns .MuiSelect-select input{

}

.select-menu-dropdowns span {
    padding: 0px 10px 0px 0px;
        max-width: 180px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
}

.select-menu-dropdowns span.Mui-checked {
    color: #003d90;
}

div#menu-sahayId .MuiMenu-list {
    height: 300px;
}




.circular-loading-user-master {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-left: 14px;}