@font-face {
  font-family: "inter-bold";
  src: url(../../../assets/fonts/inter-bold.ttf);
}

@font-face {
  font-family: "inter-medium";
  src: url(../../../assets/fonts/inter-medium.ttf);
}

.bottom-user-right input {
  font-size: 12px;
  line-height: 15px;
  font-family: "inter-medium";
  color: #333333;
}

.head-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.user-logo {
  background-color: #fff;
  width: 193px;
  height: 183px;
  margin: auto;
  box-shadow: 0px 10px 10px #00000029;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 60px; */
  margin-top: 30px;
}

.user-logo img {
  width: 175px;
  height: 150px;
  object-fit: contain;
}

.apply-sahay-main {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.user-right {
  flex: 1;
  background: #edeff1 0% 0% no-repeat padding-box;
}

.user-sidebar-main {
  width: 300px;
  max-width: 300px;
  flex: 0 0 300px;
  /* background-image: url("https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg"), transparent linear-gradient(180deg, #122B4500 0%, #122B45 100%) 0% 0% no-repeat padding-box; */
  background-image: linear-gradient(180deg, #122b4500 0%, #122b45 100%),
    url(../../../assets/images/Jpg/temple2.jpg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
  height: calc(100vh - 72px);
}

.user-Header {
  background-color: #122b45;
  padding: 18px 40px;
}

.user-Header .apply-txt {
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-family: "inter-bold";
}

.user-apply-content {
  padding: 30px 40px 40px;
}

.user-basic .head-apply {
  font-size: 14px;
  line-height: 17px;
  color: #122b45;
  text-transform: capitalize;
  padding-bottom: 15px;
  font-family: "inter-bold";
}

.basic-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d4d9e1;
  border-radius: 4px;
}

.input-width {
  max-height: 40px;
  width: 100%;
}

.al-form-groups {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.al-form-groups .basic-input::before {
  display: none;
}

.al-form-groups .basic-input input {
  padding: 6.38px 10px;
  font-size: 12px;
  line-height: 15px;
  font-family: "inter-medium";
  color: #333333;
}

.al-form-groups .css-q0jhri-MuiInputBase-root-MuiInput-root:after {
  display: none;
}

.al-form-groups .in-label {
  font-family: "inter-medium";
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 6px;
  color: #333333;
}

/* .lable-input-grp {
    margin-right: 14px;
} */

.lable-input-grp .main-select,
.lable-input-grp .main-select .MuiInputBase-formControl {
  min-height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

.lable-input-grp:nth-child(3n-3) {
  margin-right: 0;
}

.select-drop .MuiSelect-select {
  padding: 0 15px;
  font-family: "inter-medium";
  font-size: 13px;
  color: #333;
}

.select-drop:hover fieldset {
  border-color: #d4d9e1 !important;
}

.select-drop fieldset {
  border: 1px solid #d4d9e1;
}

.select-drop svg path {
  fill: #0072d5;
}

.select-drop em {
  color: #333333;
  font-size: 12px;
  line-height: 15px;
  font-style: normal;
  font-family: "inter-medium";
}

.main-in-row:first-child {
  padding: 0;
}

.main-in-row {
  padding-top: 20px;
}

.family-detail {
  flex-direction: column;
  align-items: flex-start;
}

.fam-inputs .lable-input-grp {
  margin-bottom: 15px;
}

.fam-in-grp {
  margin: 15px 0;
}

.row-bank {
  padding: 0;
}

.bank-in-grp {
  margin: 0;
}

/* .bottom-user-right {
  max-height: calc(100vh - 72px);
  overflow: auto;
} */

.toggle-switch-main button.Mui-selected,
.toggle-switch-main button.Mui-selected:hover {
  background-color: #1976d2;
  color: #fff;
}

.toggle-switch-main button,
.toggle-switch-main button:hover {
  color: #fff;
  background-color: #cbd2db;
  width: 59px;
  height: 30px;
  border-color: #cbd2db;
}

.toggle-switch-main button:first-child {
  border-radius: 4px 0px 0px 4px;
}

.toggle-switch-main button:last-child {
  border-radius: 0px 4px 4px 0px;
}

.select-file {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #d4d9e1;
  border-radius: 4px;
  color: #333333;
  text-transform: capitalize;
  font-family: "inter-medium";
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6.5px 0;
  font-size: 12px;
  line-height: 15px;
  /* position: absolute; */
}

.ori-input {
  width: 100%;
  height: 30px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.select-position {
  position: relative;
}

.select-view {
  display: flex;
  align-items: flex-end;
}

.select-view img {
  width: 61px;
  height: 61px;
  object-fit: cover;
  margin-right: 15px;
  border: 1px solid #d4d9e1;
  border-radius: 4px;
}

.select-remove {
  padding: 10px 27px;
  color: #333333;
  background: #ffc7c7 0% 0% no-repeat padding-box;
  border: 1px solid #ffa4a4;
  border-radius: 4px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 15px;
  font-family: "inter-medium";
  cursor: pointer;
}

.apply-now button {
  width: 100%;
  max-width: 300px;
  /* margin-top: 20px; */
  background: #ffffff00 0% 0% no-repeat padding-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #eaac35; */
  color: white;
  background-color: #eaac35;
  font-size: 12px;
  line-height: 15px;
  font-family: "inter-medium";
  padding: 12.5px 0;
  cursor: pointer;
  border: none;
  box-shadow: none;
  border: 1px solid #eaac35;
}
.disable-now button {
  width: 100%;
  max-width: 300px;
  /* margin-top: 20px; */
  background: #ffffff00 0% 0% no-repeat padding-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #eaac35; */
  color: white;
  background-color: #eaac3566;
  font-size: 12px;
  line-height: 15px;
  font-family: "inter-medium";
  padding: 12.5px 0;
  cursor: pointer;
  border: none;
  box-shadow: none;
  border: 1px solid #eaac3566;
}
/* .apply-now button{
   width: 100%;
    max-width: 300px;
    /* margin-top: 20px; 
    background: #ffffff00 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #eaac35;
    font-size: 12px;
    line-height: 15px;
    font-family: "inter-medium";
    padding: 12.5px 0;
    cursor: pointer;
    border: none;
    box-shadow: none;
    border: 1px solid #eaac35;
} */

.user-admin-panel-content {
  padding: 40px;
}

.apply-sahay {
  position: absolute;
  right: 40px;
  top: 15px;
}

.apply-sahay .btn {
  text-decoration: none;
  color: #052c44;
  background: #fff;
  padding: 8px 8px;
  display: flex;
  border-radius: 4px;
  font-size: 12px;
  line-height: 15px;
  font-family: "inter-medium";
}

/* .title-header-flex-2 {
    justify-content: flex-end !important;
} */
.user-heading .head-apply {
  padding: 0;
  font-size: 20px;
  line-height: 25px;
}

.apply-sahay-btn svg {
  margin-right: 5px;
  font-size: 19px;
}

.logout-user {
  color: red !important;
  padding: 3px 17px 0 !important;
}

.remove-details-content,
.remove-details-content:hover {
  padding: 2px 10px !important;
  margin-top: 22px !important;
  min-width: max-content !important;
}

/* disable Family id in apply for sahay */
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(0, 0, 0) !important;
}
.sahay-multiple-input-main .multiple-autocomplet-control{
  height: calc(100vh - 570px);
  overflow: auto;
}

.custom-lable-input-grp .sahay-multiple-input-main .multiple-autocomplet-control{
  height: 100%;
}

.sahay-multiple-input-main .fix{
  height: calc(100vh - 621px);
  overflow: auto;
  padding-right: 10px;
  width: 140px;
}

.required {
  color: red;
}
