.ds-table-design-main tr th,
.ds-table-design-main tr td {
  font-size: 12px;
  color: #122b45;
  /* line-height: 15px; */
}

.ds-table-design-main tr th {
  font-family: "inter-semibold";
  padding: 4px 9px !important;
}

.ds-table-design-main tr td {
  font-family: "inter-regular";
  padding: 10px 9px 10px 15px !important;
  border-bottom: 0 !important;
  /* border: 1px solid rgb(192, 255, 212); */
}

.ds-table-design-main {
  box-shadow: none !important;
  border: 0 !important;
}

.ds-table-design-main tr td:last-child button.MuiIconButton-sizeMedium {
  padding: 3.5px;
}
.ds-table-design-main tr th {
  background: #a6ccff;
  padding: 10px !important;
  white-space: nowrap;
}

.ds-table-design-main {
  /* background: #f0f3f8; */
  border-collapse: separate !important;
  /* border-spacing: 0px 8px !important; */
  border-radius: 0px !important;
}

.ds-table-design-main tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.ds-table-design-main tr td:first-child {
  border-radius: 10px 0 0 10px !important;
}

.ds-table-design-main tr td {
  background: #fff;
  display: table-cell;
}

.ds-table-design-main tr th:first-child,
.ds-table-design-main tr td:first-child {
  border-radius: 10px 0px 0px 10px;
}

.ds-table-design-main tr th:last-child,
.ds-table-design-main tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
}
.ds-table-design-main tr td:first-child {
  padding-left: 20px !important;
  white-space: nowrap;
}

.user-master-container .ds-user-master-table {
  min-width: 1000px;
}

.ds-user-master-table tr th:nth-child(1),
.ds-user-master-table tr td:nth-child(1) {
  width: 5%;
}

.ds-user-master-table tr th:nth-child(2),
.ds-user-master-table tr td:nth-child(2) {
  width: 10%;
}

.ds-user-master-table tr th:nth-child(3),
.ds-user-master-table tr td:nth-child(3) {
  width: 15%;
}

.ds-user-master-table tr th:nth-child(4),
.ds-user-master-table tr td:nth-child(4) {
  width: 10%;
}

.ds-user-master-table tr th:nth-child(5),
.ds-user-master-table tr td:nth-child(5) {
  width: 10%;
}

.ds-user-master-table-inner tr th,
.ds-user-master-table-inner tr td {
  border-bottom: 0;
}
.user-master-container .user-master-table-inner {
  min-width: 100%;
  border-spacing: 0 !important;
  margin-top: 7px;
}

.ds-user-master-table-inner tr th {
  background: #fff;
  padding: 5px 30px !important;
}
.ds-user-master-table-inner tr td {
  padding: 5px 0px !important;
}
.ds-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ds-flex-end .total-amount {
  font-size: 14px;
  margin-right: 1.5rem;
}
.sahay-multiple-input-main .date-select {
  margin-top: 2px;
}
