.user-setting-tab-details .user-setting-tabs .user-setting-tab {
    color: #000000;
    font-size: 15px;
    line-height: 23px;
    font-family: 'inter-regular';
    text-transform: capitalize;
}

.user-setting-tab-details .user-setting-tabs .user-setting-tab.Mui-selected {
    color: #003d90;
    font-family: 'inter-bold';
}

.user-setting-details{
    padding: 30px 0px;
}

.update-profile-components .p-15 {
    padding: 0px;
}

.update-profile-components .profile-content{
    height: initial;
    min-height: initial;
}

.update-profile-components  .profile-card-main {
    max-width: 100%;
    padding: 0px;
}