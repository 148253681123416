* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* border: 2px solid black; */
}

@font-face {
  font-family: "inter-bold";
  src: url(../fonts/inter-bold.ttf);
}

@font-face {
  font-family: "inter-medium";
  src: url(../fonts/inter-medium.ttf);
}

@font-face {
  font-family: "inter-regular";
  src: url(../fonts/inter-regular.ttf);
}

@font-face {
  font-family: "inter-semibold";
  src: url(../fonts/inter-semibold.ttf);
}

.d-flex,
.res-menu-icon {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-auto {
  margin-left: auto;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.h-100 {
  height: 100%;
}

.header-main {
  background: #003d90;
  padding: 4px 15px;
}

.position-relative {
  position: relative;
}

.header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-ul {
  display: flex;
  color: #d4d9e1;
}

.header-ul li {
  font-family: "inter-regular";
  font-size: 14px;
  width: auto;
  padding: 0 10px;
}

.logo-img {
  width: 178px;
  object-fit: cover;
  height: 38px;
}

.header-ul li a {
  text-decoration: none;
  color: #d4d9e1;
}

.p-15 {
  padding: 15px;
}

.title-header {
  display: flex;
  align-items: center;
}

.title-header-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.title-header-flex-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.search {
  border: 1px solid #d4d9e1;
  width: 200px !important;
  height: 35px;
  line-height: 31px;
  border-radius: 4px !important;
  margin-right: 10px !important;
}

.search input::placeholder {
  color: #919cac;
  opacity: 1;
}

.search input {
  font-size: 12px;
  line-height: 15px;
  padding: 5px 14px !important;
  font-family: "inter-regular";
}

.dashboard-text p,
.title-main .page-title {
  color: #003d90;
  font-size: 19px;
  line-height: 27px;
  font-family: "inter-bold";
}

.common-button button,
.common-button button:hover {
  font-size: 12px;
  font-family: "inter-semibold";
  padding: 7px 13px 6px 13px;
  line-height: 19px;
  box-shadow: 0px 3px 4px #ccc4b3;
  letter-spacing: 0px;
  border-radius: 4px;
  text-transform: capitalize;
  margin-right: 10px;
}

/* .common-button button .back_button{
    align-items: center;
    justify-content: center;
} */

.grey-button button,
.grey-button button:hover {
  background: #fff;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 4px;
}

.blue-button button,
.blue-button button:hover {
  background: #eaac35;
  color: #ffffff;
  /* border: 1px solid #0D70D9; */
  /* margin-left: 10px; */
  border-radius: 4px;
  border: 0px;
  padding: 5.5px 8px;
}

.blue-button-disable-now,
.blue-button-disable-now:hover {
  background-color: #eaac3566;
  color: #ffffff;
  border: 1px solid #eaac3566;
  /* border: 1px solid #0D70D9; */
  /* margin-left: 10px; */
  border-radius: 4px;
  border: 0px;
  padding: 5.5px 8px;
}

/* .disable-now button {
  width: 100%;
  max-width: 300px;
  /* margin-top: 20px; 
  background: #ffffff00 0% 0% no-repeat padding-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #eaac35; 
  color: white;
  background-color: #eaac3566;
  font-size: 12px;
  line-height: 15px;
  font-family: "inter-medium";
  padding: 12.5px 0;
  cursor: pointer;
  border: none;
  box-shadow: none;
  border: 1px solid #eaac3566;
} */

.back-btn button {
  padding: 10.5px 8px !important;
  font-family: "inter-medium";
}

.table-design-main tr th,
.table-design-main tr td {
  font-size: 12px;
  color: #122b45;
  /* line-height: 15px; */
  border: none;
}

.table-design-main tr th {
  font-family: "inter-semibold";
  padding: 4px 9px !important;
}

.table-design-main tr td {
  font-family: "inter-regular";
  padding: 10px 9px 10px 15px !important;
  border-bottom: 0 !important;
  /* border: 1px solid rgb(192, 255, 212); */
}

.table-design-main {
  box-shadow: none !important;
  border: 0 !important;
}

.invoice-table tr td {
  background: #fff;
  display: table-cell;
  text-align: center;
}

.input-design-div .password {
  margin-top: 25px !important;
}

.input-design-div .MuiFilledInput-underline:before,
.input-design-div .MuiFilledInput-underline:after,
.input-design-div .MuiFilledInput-underline:hover:before {
  border-bottom: 0 !important;
}

.input-design-div .MuiFilledInput-underline,
.input-design-div .MuiFilledInput-underline:hover,
.input-design-div .MuiFilledInput-underline.Mui-focused {
  background: #fff;
  border: 1px solid #dfe0e1;
  /* border-radius: 10px; */
  border-radius: 4px;
  height: 28px;
}

.input-design-div .input-placeholder input::placeholder {
  color: #122b45;
  font-family: "inter-regular";
  /* font-size: 16px; */
  line-height: 20px;
}

.admin-input-design {
  width: 100%;
}

.admin-input-design {
  width: 100%;
}

.input-design-div.with-border input {
  /* height: 28px; */
  height: 30px;
  line-height: 28px;
  box-sizing: border-box;
  padding: 0 15px;
}

.input-design-div.with-border input::placeholder {
  font-size: 14px;
  line-height: 18px;
}

.input-design-div.with-border input,
.set-textarea-box-top .MuiInputBase-formControl {
  border: 1px solid #ccc;
  /*border-radius: 8px;
    */
  border-radius: 4px !important;
  background-color: #fff;
  font-family: "inter-regular";
  font-size: 14px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.set-textarea-box-top fieldset {
  border: none !important;
}

.with-border .MuiFilledInput-underline {
  /* height: 28px !important; */
  height: 30px !important;

  font-size: 14px !important;
  letter-spacing: 0;
}

.input-label {
  color: #122b45;
  font-size: 16px;
  line-height: 15px;
  font-family: "inter-semibold";
  padding-bottom: 8px !important;
  display: flex;
}

.input-label-number {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-semibold";
  margin-top: 2px !important;
  display: flex;
}

.line {
  height: 1px;
  background: #ebebeb;
}

.save-btn button {
  min-width: 110px;
}

.table-container {
  box-shadow: none !important;
}

.set-date .MuiStack-root {
  overflow: hidden;
  padding-top: 0px;
}

.set-date-picker .MuiInputBase-colorPrimary {
  background: #fff;
  border: 1px solid #00000033 !important;
  border-radius: 4px;
  width: 100%;
  /* height: 28px; */
  /* border-radius: 10px; */
  height: 35px;
  border-radius: 5px;
}

.set-date-picker fieldset {
  border-color: transparent !important;
}

.set-date-picker input {
  padding: 0px 14px !important;
  color: #120d26;
  font-size: 14px !important;
  line-height: 19px;
  font-family: "inter-regular" !important;
  text-align: left;
  height: 28px;
}

.set-date-picker .MuiStack-root {
  padding-top: 0px !important;
}

.set-calendar-box .set-calendar-position,
.set-date-picker .MuiStack-root,
.input-design-div-people {
  width: 100%;
}

.accestimeicon {
  fill: #757575 !important;
}

.accestimeicon-img {
  position: absolute;
  top: 5px;
  right: 8px;
  display: flex;
}

.accestimeicon-img svg {
  height: 19px;
}

.set-date-picker svg {
  height: 19px;
}

.pagination-design nav {
  display: flex;
}

.pagination-design nav ul li .MuiPaginationItem-previousNext {
  color: #003d90;
}

.pagination-design nav ul li button,
.pagination-design nav ul li button:hover {
  min-width: auto;
  height: auto;
  background: transparent;
  margin: 0;
  font-family: "inter-medium";
  color: #99a4b5;
}

.pagination-design nav ul li button.Mui-selected,
.pagination-design nav ul li button.Mui-selected:hover {
  background: transparent;
  color: #003d90;
}

.pagination-design {
  margin-top: 15px;
  align-items: center;
}

.filter-btn button,
.filter-btn button:hover {
  max-width: 34px;
  height: 34px;
  min-width: 34px;
  background: #003d90;
  border: 1px solid #003d90;
}

/* 10-5 css */

.main-login {
  background-image: url("../images/Jpg/temple.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.rightbox {
  background: #042443c4;
  height: 100vh;
  margin-left: auto;
  max-width: 50%;
  border-radius: 0px 0px 0px 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-login-main {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-set {
  padding: 30px 0px 0px 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-set img {
  height: 81px;
  width: 383px;
  object-fit: contain;
}

.logo-set2 img {
  /* height: 100px; */
  width: 120px;
  object-fit: contain;
}

.box-text p {
  color: white;
  font-size: 32px;
  line-height: 39px;
  font-family: "inter-bold";
}

.box-login-text p {
  font-family: "inter-regular";
  font-size: 16px;
  color: white;
  line-height: 20px;
  margin: 15px 0px 40px 0px;
}

.main-box {
  max-width: 300px !important;
  width: 300px;
  flex: 0 0 300px;
}

.login-btn-main {
  margin: 30px 0px 20px;
}

.login-btn button {
  width: 100%;
  font-family: "inter-semibold";
}

.orange-btn button,
.orange-btn button:hover,
.orange-btn .buttons,
.orange-btn .buttons:hover {
  background: #eaac35;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: none;
  min-height: 36px;
  font-family: "inter-regular";
  text-transform: capitalize;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  min-width: 300px;
  box-shadow: 0px 3px 4px #ccc4b3;
}

.forgot p {
  margin: 0px !important;
  text-align: right !important;
}

.input-design-div .MuiInputBase-input {
  /* padding: 10px 16px !important; */
  padding: 3px 10px !important;
  color: #122b45;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding-right: 30px;
}

.dashboard-text p {
  color: #003d90;
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-bold";
  display: flex;
}

.main-box-dash {
  background: #f0f3f8;
  border-radius: 8px;
  max-width: 18.81%;
  min-height: 73px;
  padding: 14px 20px;
  width: calc(100% / 5);
  /* border: 1px solid #DFE0E1; */
  margin: 10px 7.5px 15px;
}

.text-main p {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  font-family: "inter-medium";
  margin-bottom: 5px;
}

.number-text p {
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-bold";
  color: #122b45;
}

.logo-dash img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.dash-text {
  margin-left: 20px !important;
}

.dash-text-box-set {
  display: flex;
  align-items: center;
}

.set-dash-main {
  display: flex;
  width: calc(100% + 15px);
  margin-left: -7.5px;
  flex-wrap: wrap;
  margin-right: -7.5px;
}

.forgot a {
  text-decoration: none;
}

.barge-list-main-box {
  border-radius: 8px;
  /* / max-width: 323px; / */
  max-width: 100%;
  min-height: 104px;
  /* / padding: 12px 15px 10px; / */
  /* / width: calc(100% / 2); / */
  /* border: 1px solid #DFE0E1; */
  /* / margin: 15px 6.5px 0px; / */
  background: #f0f3f8;
}

.barge-set-dash-main {
  display: flex;

  /* / width: calc(50% + 15px); / */
  margin-left: -6.5px;
  flex-wrap: wrap;
  margin-right: -6.5px;
}

.brge-icon .brge-icon-set {
  width: 66px;
  height: 59px;
}

.set-progress {
  margin: 3px 0px 1px;
}

.set-progress span {
  width: 66px !important;
  height: 4px !important;
}

.set-progress .MuiLinearProgress-bar {
  background-color: #0d70d9 !important;
}

.brge-number .text {
  font-size: 8px;
  line-height: 10px;
  font-family: "inter-medium";
  color: #122b45;
}

.brge-img-set {
  padding: 12px 15px 10px;
  width: 30%;
  border-right: 1px solid #dfe0e1;
}

.barge-set-text-box {
  padding: 8px 8px 10px 15px;
  width: 100%;
  text-align: left;
}

.main-barge-box {
  display: flex;
}

.set-scheduled .text {
  font-size: 8px;
  line-height: 10px;
  font-family: "inter-regular";
  color: white;
  padding: 1px 6px;
}

.set-scheduled {
  border-radius: 30px;
  width: 100%;
  max-width: max-content;
  margin-left: auto;
  height: 12px;
}

.set-scheduled-text .text-name {
  font-size: 10px;
  line-height: 12px;
  font-family: "inter-regular";
  color: #8d98ab;
}

.set-scheduled-text .text-span {
  font-size: 10px;
  line-height: 12px;
  font-family: "inter-medium";
  color: #122b45;
}

.pt-5 {
  padding-top: 5px !important;
}

.set-scheduled-back {
  background: #51c6e5;
}

.set-available-back {
  background-color: #05e39c;
}

.main-bargebox {
  display: flex;
  width: 100%;
}

.todaytrip-list-main-box {
  background: #f0f3f8;
  border-radius: 8px;
  max-width: 100%;
  min-height: 104px;
  /* / padding: 12px 15px 10px; / */
  /* / width: calc(100% / 2); / */
  /* border: 1px solid #DFE0E1; */
  /* / margin: 15px 6.5px 0px; / */
}

.dashboard-row-main .dashboard-item,
.dashboard-item {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent;
  text-align: center !important;
}

.background-ed {
  background: #fff;
  border-radius: 10px;
}

.today-trip-text {
  padding-top: 12px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.today-set-progress.set-progress .MuiLinearProgress-bar {
  background-color: #e8b153 !important;
}

.admin-input-design {
  width: 100%;
}

.profile-set {
  font-family: "inter-regular";
  font-size: 14px;
  width: auto;
  padding: 0 10px;
  color: #d4d9e1;
  text-transform: capitalize;
}

.flex-drop-main {
  display: flex;
  align-items: center;
}

.admin-header-profile-icon {
  height: 26px;
  width: 26px;
  object-fit: cover;
  border-radius: 50%;
}

.arrow-profile-icon {
  height: 5px;
  width: 5px;
  object-fit: cover;
}

.admin-header-profile-ul a li {
  color: #122b45;
  font-size: 14px;
  line-height: 17px;
  font-family: "inter-regular";
}

.admin-header-profile-ul a {
  text-decoration: none !important;
}

.logout-profile {
  color: #f5125f !important;
  font-size: 14px !important;
}

.admin-profile-box .MuiPaper-rounded {
  top: 50px !important;
  right: 32px !important;
  box-shadow: 0px 3px 6px #0000000d !important;
  border-radius: 10px !important;
  left: 0px !important;
}

/* / start admin header css / */
.admin-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* / end admin header css / */

/* 10-5 css end */
.filter-header {
  background: #edeff1;
  padding: 15px 20px;
}

.filter-main .MuiPaper-elevation {
  min-width: 350px;
}

.filter-header-inner .filter-title {
  color: #003d90;
  font-family: "inter-bold";
  font-size: 16px;
  line-height: 20px;
}

.filter-footer {
  height: 50px;
  border: 1px solid #edeff1;
  padding: 10px 20px;
}

.filter-inner-main {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 20px;
}

.filter-header-inner {
  justify-content: space-between;
}

.outline-button button,
.outline-button button:hover {
  background: transparent;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 4px;
}

.profile-content {
  height: 100%;
  min-height: calc(100vh - 120px);
  /* margin-top: 50px; */
}

.card-main {
  background: #f0f3f8;
  padding: 30px;
  box-shadow: 0px 3px 30px #0000000d;
  border-radius: 10px;
}

.profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  margin: auto;
  margin-bottom: 15px;
}

.profile-card-main {
  max-width: 710px;
}

.profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  /* margin: auto; */
  margin-bottom: 15px;
}

.change-input-label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-medium";
  padding-bottom: 6px !important;
  display: flex;
}

.set-textarea-box {
  height: 47px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-textarea-box:focus {
  outline: none;
}

.change-profile {
  display: flex;
  justify-content: center;
  position: relative;
}

input.change-profile-input {
  position: absolute;
  top: 1px;
  background: red;
  height: 28px;
  right: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
}

.change-password-set {
  margin-bottom: 20px;
}

.change-password-main {
  min-width: 370px;
}

.change-space {
  margin-bottom: 15px;
}

.supply-table tr th:nth-child(1),
.supply-table tr td:nth-child(1) {
  width: 6%;
}

.supply-table tr th:nth-child(2),
.supply-table tr td:nth-child(2) {
  width: 10%;
}

.supply-table tr th:nth-child(3),
.supply-table tr td:nth-child(3) {
  width: 10%;
}

.supply-table tr th:nth-child(4),
.supply-table tr td:nth-child(4) {
  width: 5%;
}

.supply-table tr th:nth-child(5),
.supply-table tr td:nth-child(5) {
  width: 11%;
}

.supply-table tr th:nth-child(6),
.supply-table tr td:nth-child(6) {
  width: 6%;
}

.supply-table tr th:nth-child(7),
.supply-table tr td:nth-child(7) {
  width: 8%;
}

.supply-table tr th:nth-child(8),
.supply-table tr td:nth-child(8) {
  width: 5%;
}

.supply-table tr th:nth-child(9),
.supply-table tr td:nth-child(9) {
  width: 5%;
}

.supply-table tr th:nth-child(10),
.supply-table tr td:nth-child(10) {
  width: 4%;
}

.supply-table tr th:nth-child(11),
.supply-table tr td:nth-child(11) {
  width: 7%;
}

.supply-table tr th:nth-child(12),
.supply-table tr td:nth-child(12) {
  width: 10%;
}

.supply-table tr th:nth-child(13),
.supply-table tr td:nth-child(13) {
  width: 18%;
}

.supply-table tr th:nth-child(14),
.supply-table tr td:nth-child(14) {
  width: 10%;
}

.tanker-table tr th:nth-child(1),
.tanker-table tr td:nth-child(1) {
  width: 12%;
}

.tanker-table tr th:nth-child(2),
.tanker-table tr td:nth-child(2) {
  width: 20%;
}

.tanker-table tr th:nth-child(3),
.tanker-table tr td:nth-child(3) {
  width: 12%;
}

.tanker-table tr th:nth-child(4),
.tanker-table tr td:nth-child(4) {
  width: 14%;
}

.tanker-table tr th:nth-child(5),
.tanker-table tr td:nth-child(5) {
  width: 18%;
}

.tanker-table tr th:nth-child(6),
.tanker-table tr td:nth-child(6) {
  width: 17%;
}

.tanker-table tr th:nth-child(7),
.tanker-table tr td:nth-child(7) {
  width: 20%;
}

.change-password-btn button {
  margin-left: 0 !important;
  margin-top: 5px;
}

.res-menu {
  display: none;
}

.res-menu-icon svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.res-menu-icon svg {
  display: none;
}

.res-close-btn {
  display: none !important;
}

.header-flex-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.set-filter-main {
  padding-top: 60px;
}

.background-hight {
  height: 100%;
}

.header-ul-main-open {
  left: -250px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  width: 160px;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.no-text-decoration {
  text-decoration: none;
}

.margin-left-5 {
  margin-left: 5px !important;
}

/* start add user modal css */

.span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.modal .modal-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
}

.modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}

.modal .add-user-modal-inner-main {
  padding: 24px 24px 30px 24px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.modal-input-box {
  margin-bottom: 24px;
}

.modal-input-box .form-group .form-control input {
  padding: 5.5px 15px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* end add user modal css */
/* start delete user modal css */

.user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.modal-delete .delete-modal-inner-main {
  padding: 40px 24px 40px 24px;
  max-width: 700px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
  overflow: scroll;
  max-height: 600px;
  width: 100%;
  box-shadow: 0 4px 8px 0 #ffffff, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.schedule-modal-par .schedule-modal {
  padding: 10px 24px 40px 24px;
  max-width: 480px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;

  max-height: 390px;
  width: 100%;
  overflow: unset;
}

.modal-delete .approve-amount-model {
  padding: 10px 24px 40px 24px;
  max-width: 480px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
  /* overflow: scroll; */
  overflow: auto;
  max-height: 390px;
  width: 100%;
}

/* .modal-delete .delete-modal-inner-main {
  padding: 40px 24px 40px 24px;
  max-width: 300px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
} */

.delete-modal-inner-main .delete-modal-title {
  color: #111827;
  font-size: 28px;
  line-height: 42px;
  font-family: "inter-medium";
  text-align: center;
  margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
  color: grey;
  margin-bottom: 26px;
  text-align: center;
  font-family: "poppins-regular";
  max-width: 304px;
  width: 100%;
  margin: auto;
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.delete-modal-btn-flex .modal-btn {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  max-width: 100px;
  width: 100%;
  padding: 8px;
  text-transform: capitalize;
}

.delete-modal-btn-flex .modal-cancel-btn {
  background: #fff;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 10px;
  margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #ffffff;
  background: #eaac35;
  border-radius: 10px;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */

.incre-btn-main .inc-dic-btn,
.incre-btn-main .inc-dic-btn:hover {
  background-color: #003d90;
  height: 34px;
  min-width: 34px;
  color: #fff;
  line-height: 23px;
  font-size: 17px;
  margin-top: 15px;
  border-radius: 5px;
  padding-bottom: 8px;
}

.incre-btn-main .inc-dic-btn:first-child {
  margin-right: 15px;
}

.incre-btn-main .Mui-disabled {
  color: #c5c1c1 !important;
}

.Mui-error {
  margin-left: 0px !important;
}

.action-menu-icon {
  font-size: 20px !important;
}

.action-text {
  font-family: "inter-regular" !important;
  color: #122b45 !important;
  font-size: 13px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 23-5 */
.filter-datepicker .MuiOutlinedInput-input {
  border: 0 !important;
}

.filter-date-overflow>.MuiStack-root {
  overflow: hidden;
}

.pdf-design.MuiBox-root.css-0 {
  font-family: "inter-medium";
  font-size: 14px;
  background: #003d90;
  color: #d8d8dd;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
}

.pdf-upload-design {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.filter-close-btn img {
  height: 23px;
  width: 23px;
}

.modal-close-button {
  height: 23px;
  width: 23px;
}

/* 9-6 */
/* / select compny start / */
.select-compny-cad-main {
  max-width: 1118px !important;
  width: 100%;
}

.select-card-main {
  background: #fff;
  padding: 50px;
  box-shadow: 0px 3px 30px #0000000d;
  border: 1px solid #e2e2e2;
  border-radius: 15px;
}

.title-main .select-page-title {
  color: #000000;
  font-size: 30px;
  line-height: 36px;
  font-family: "inter-bold";
  text-align: center;
}

.compny-img {
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 20.5px;
  background: white;
  cursor: pointer;
}

.compny-img-inner {
  width: 196px;
  height: 158px;
  border-radius: 10px;
}

.set-select-img {
  text-align: center;
}

.select-compny-text {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-semibold";
  color: #122b45;
}

.select-text-set {
  margin-top: 20px;
}

.select-img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.select-img-main {
  margin: 9px;
  width: 241px;
}

.mb-41 {
  margin-bottom: 41px;
}

.select-check-box {
  text-align: center;
  justify-content: center;
  margin-top: 15px;
}

.select-check-box svg {
  width: 30px;
  height: 30px;
}

.select-check-box .MuiCheckbox-colorPrimary {
  padding-top: 16px 0px 0px !important;
}

.select-modal-box {
  max-width: 551px !important;
  width: 100% !important;
  border: 1px solid #707070 !important;
  padding: 0px !important;
  border-radius: 20px !important;
  outline: none;
  cursor: pointer;
}

.select-modal-header {
  background: #003d90;
  padding: 15px 20px;

  border-radius: 19px 19px 0px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.select-modal-header-text {
  font-size: 18px;
  color: white;
  line-height: 20px;
  font-family: "inter-semibold";
}

.select-modal-img-box {
  padding: 40px 50px;
}

.select-text-set-modal {
  margin-top: 9px;
}

.modal-compny-img-inner {
  width: 94px;
  height: 101px;
  border-radius: 10px;
}

.modal-compny-img {
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 13px 14px;
  background: white;
}

.modal-select-img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.select-img-main-modal {
  margin: 14px;
  width: 155px;
}

.modal-set-select-img {
  text-align: center;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}

.modal-select-compny-text {
  text-align: center;
  font-size: 15px;
  line-height: 19px;
  font-family: "inter-semibold";
  color: #122b45;
}

.set-modal-close-icon {
  height: 30px;
  width: 30px;
}

.modal-close-img {
  height: 16px;
  width: 16px;
}

.modal-close-icon-small {
  height: 20px;
  width: 20px;
}

.modal-icon-btn {
  margin-top: 11px !important;
}

.res-btn-close {
  min-width: auto !important;
  padding: 0px !important;
}

.modal-compny-img .active {
  border: 1px solid #1f65db;
}

.select-img-main-modal.active .modal-compny-img {
  border: 2px solid #1f65db;
}

.select-img-main-modal.active .modal-icon-btn .modal-close-icon-small-grey {
  display: none;
}

.select-img-main-modal.active .modal-icon-btn .modal-close-icon-small-blue {
  display: flex;
  margin: auto;
}

.select-img-main-modal .modal-icon-btn .modal-close-icon-small-grey {
  display: flex;
  margin: auto;
}

.select-img-main-modal .modal-icon-btn .modal-close-icon-small-blue {
  display: none;
}

.select-img-main.active .compny-img {
  border: 2px solid #1f65db;
}

.select-img-main.active .select-check-box .modal-close-icon-small-blue {
  display: block;
  margin: auto;
}

.select-img-main.active .select-check-box .modal-close-icon-small-grey {
  display: none;
}

.select-img-main .select-check-box .modal-close-icon-small-grey {
  display: block;
  margin: auto;
}

.select-img-main .select-check-box .modal-close-icon-small-blue {
  display: none;
}

/* / select compny end / */
.barge-dashboard-box {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dfe0e1;
  border-radius: 10px;
}

.barge-common-box {
  background-color: #f0f3f8;
  padding: 20px;
  /* border: 1px solid #DFE0E1; */
  border-radius: 10px;
}

/* .table-design-main {
  background-color: #f0f3f8;
} */

/* .page-content-box {
    margin-top: 30px;
    margin-bottom: 30px;
  } */
.dashboard-containt-main {
  padding: 60px 30px 0px;
}

.filter-date-overflow .MuiStack-root {
  width: 100%;
}

.barge-dashboard-page {
  margin-top: 30px;
  margin-bottom: 30px;
}

.one-line-table tr th,
.one-line-table tr td {
  white-space: nowrap;
}

.table-design-main tr td:last-child button.MuiIconButton-sizeMedium {
  padding: 3.5px;
}

.input-design-div .MuiInputBase-fullWidth {
  /* border-radius: 10px; */
  border-radius: 4px;
  height: 30px;
  line-height: 25px;
}

.input-design-div .set-date-picker .MuiInputBase-input {
  background: transparent;
}

/* .table-design-main tr th:last-child, .table-design-main tr td:last-child {
    width: 50px;
  } */

.white-login-box.MuiBox-root.css-0 {
  background: #fff;
  padding: 50px;
  border-radius: 15px;
}

.bluebox-text p {
  color: #122b45;
}

.login-input-design-div .MuiFilledInput-underline,
.login-input-design-div .MuiFilledInput-underline:hover,
.login-input-design-div .MuiFilledInput-underline.Mui-focused {
  height: 36px;
  border: 1px solid #d4d9e1;
}

.logo-set2 {
  margin-bottom: 10px;
}

/* / 14/6/23 start css / */

.profile-image-center {
  text-align: center;
}

.input-design-div-set-back .MuiInputBase-input {
  background-color: transparent !important;
}

.change-password-icon .admin-input-design .MuiInputAdornment-filled button svg {
  width: 18px;
  height: 36px;
}

.change-password-icon .MuiInputBase-input {
  font-size: 14px !important;
}

.change-password-text {
  margin-bottom: 20px;
}

.input-design-div .password-set {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
}

.input-design-div .password-set-box {
  margin-top: 0px !important;
}

.input-design-div .label-set {
  margin-top: 0px !important;
}

.input-label-new {
  background: #f0f3f8 !important;
}

/* / 14/6/23 start css / */

body .MuiPopover-paper {
  box-shadow: none !important;
  border-radius: 8px;
  border: 1px solid #e1dddd;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

/* table start */
.table-design-main tr th {
  background: #a6ccff;
  padding: 15px !important;
  white-space: nowrap;
}

.table-design-main .user-sahay-thead .user-sahay-tr {
  background: #a6ccff !important;
  /* padding: 9px !important; */
  white-space: nowrap !important;
}


.dashboard-containt-main {
  padding: 60px 30px 0px;
  background: #f0f3f8 !important;
}

.table-design-main {
  /* background: #f0f3f8; */
  border-collapse: separate !important;
  /* border-spacing: 0px 8px !important; */
  border-spacing: 0px 0px 0px 4px !important;
  border-radius: 0px !important;
}

/* .table-design-main tr th:first-child,
.table-design-main tr td:first-child {
  padding-left: 20px !important;
} */

/* .table-design-main tr th:last-child,
.table-design-main tr td:last-child {
  padding-right: 20px !important;
} */

.barge-common-box-table {
  background-color: #f0f3f8;
  padding: 0px !important;

  border-radius: 10px;
}

.table-design-main tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.table-design-main tr td:first-child {
  border-radius: 10px 0 0 10px !important;
}

.table-design-main tr td {
  background: #fff;
  display: table-cell;
}

.table-design-main tr th:first-child,
.table-design-main tr td:first-child {
  border-radius: 10px 0px 0px 10px;
}

.table-design-main tr th:last-child,
.table-design-main tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
  width: 20%;
}

.profile-inner .dashboard-item {
  background: transparent;
}

.set-textarea-box {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-profile-set {
  font-size: 14px;
  font-family: "inter-bold";
  line-height: 20px;
  color: #122b45;
  padding: 0px 8px;
  text-transform: capitalize;
  display: flex;
}

.set-admin-header-profile-icon {
  width: 33px !important;
  height: 33px !important;
}

.set-profile-inner {
  font-size: 8px;
  line-height: 10px;
  color: #959595;
  padding: 0px 8px;
  font-family: "inter-regular";
}

.new-menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  top: 51px !important;
  width: 200px;
}

.pink-btn-menu {
  background: #ffb8d0 !important;
  text-align: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  margin: 0px 16px !important;
}

.drop-header-btn-set {
  padding: 0px 0px 6px !important;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 16px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  top: 63px !important;
  width: 200px;
}

.css-2hzjrb-MuiInputBase-input-MuiFilledInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0) !important;
}

.table-design-main tr td:first-child {
  padding-left: 20px !important;
  white-space: nowrap;
}

.input-design-div fieldset {
  border-color: #ccc;
}

.invoice-table tr td {
  white-space: nowrap;
}

.paiddot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: green;
  border-radius: 50%;
  margin-right: 10px;
}

.unpaiddot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  margin-right: 10px;
}

.MuiPopover-root .MuiPaper-root .MuiList-root li.Mui-selected {
  background: #a6ccff;
}

.input-design-div .MuiOutlinedInput-root:hover fieldset {
  border-color: #ccc;
  border-width: 1px;
}

.MuiOutlinedInput-root .MuiSelect-select .multiselect-item {
  display: inline-flex;
  background: #c9e0ff;
  font-size: 10px;
  padding: 0 8px;
  border-radius: 3px;
  line-height: 2;
  font-family: "poppins-semibold";
  color: #003d90;
}

.invoice_number {
  display: flex;
  justify-content: space-between;
}

.invoice_number_align {
  margin-left: 10px !important;
  font-size: 16px !important;
}

.party_btn {
  display: flex !important;
  justify-content: start;
  margin-right: "-20px";
}

.admin-page-title {
  color: #003d90;
  font-size: 19px;
  line-height: 20px;
  font-family: "inter-bold";
}

.sidebar-logo-back {
  background-color: white;
  width: 151px;
  height: auto;
  margin: auto;
  display: flex;
}

.error-text {
  color: #eb3030;
  font-family: "inter-medium";
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0 0;
}

/* .trust-main-div {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
} */

.sahay-image {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.muiico-edit {
  height: 0.8em !important;
  color: #eaac35 !important;
}

.muiico-delete {
  height: 0.8em !important;
  color: rgb(217, 29, 29) !important;
}

.muiico-view {
  height: 0.8em !important;
  /* color: #7fb5fc !important; */
  color: #003d90;
}

.muicheckbox-delete .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}

.icon-cell {
  display: flex;
}

.muiico-view {
  height: 0.8em !important;
  /* color: #7fb5fc !important; */
  color: #003d90;
}

.icon-cell {
  display: flex !important;
}

.select-view-document-name {
  /* background-color:rgb(248, 244, 244); */
  width: 100%;
  /* height: 40px; */
  /* border: 1px solid rgb(189, 187, 187); */
  /* padding-left: 10px; */
  /* margin-top: 15px; */
  margin-top: 23px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.remove-margin-upload {
  margin-top: 0;
}

.multiple-doc {
  margin-bottom: 30px;
}

.document-name {
  display: flex;
  align-items: center;
  margin: 0 15px 5px 0;
  background: white;
  padding: 2px 7px;
  border-radius: 5px;
}

.document-type-title {
  background: transparent;
}

.document-name p {
  font-family: "inter-regular";
  font-size: 12px;
  line-height: 20px;
}

.document-name button {
  padding: 0;
}

.document-name button svg {
  font-size: 17px;
  padding-left: 6px;
}

.muiico-cancel {
  height: 0.8em !important;
  text-align: center;
  color: black !important;
  font-weight: bold !important;
}

.tablecell {
  width: 20%;
  height: 25px !important;
}

.tablecell-field .MuiInputBase-input {
  /* height: 25px !important */
  padding: 6.38px 10px;
}

/* multiple document upload css in apply for sahay */

.document-view-main .document-view-btn {
  background: #eaac35;
  color: #ffffff;
  font-size: 12px;
  margin-left: 10px;
  border-radius: 4px;
  border: 0px;
  padding: 10.5px 8px;
  font-family: "inter-medium";
}

.document-view-main .document-view-btn,
.document-view-main .document-view-btn:hover {
  background: #eaac35;
}

.table-data-not-found {
  text-align: center;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: gray;
}

.loading-spinner {
  align-items: center !important;
  display: flex;
  justify-content: center;
  /* max-height: 250px;
  height: 290px; */
  height: calc(100vh - 122px);
}

/* .loading-table {
    height: 290px;
  } */

.circular-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  margin-left: 450px;
}

/* table width */

/* Sahay Type Master */

.sahay-type-container {
  max-width: calc(100vw - 60px);
}

.sahay-type-container .sahay-type-table {
  min-width: 500px;
}

.sahay-type-table tr th:nth-child(1),
.sahay-type-table tr td:nth-child(1) {
  width: 33.33%;
}

.sahay-type-table tr th:nth-child(2),
.sahay-type-table tr td:nth-child(2) {
  width: 33.33%;
}

.sahay-type-table tr th:nth-child(3),
.sahay-type-table tr td:nth-child(3) {
  width: 33.33%;
}

/* sub sahay  */

.sub-type-list-container {
  max-width: calc(100vw - 60px);
}

.sub-type-list-container .sub-type-list-table {
  min-width: 900px;
}

.sub-type-list-table tr th:nth-child(1),
.sub-type-list-table tr td:nth-child(1) {
  width: 25%;
}

.sub-type-list-table tr th:nth-child(2),
.sub-type-list-table tr td:nth-child(2) {
  width: 25%;
}

.sub-type-list-table tr th:nth-child(3),
.sub-type-list-table tr td:nth-child(3) {
  width: 20%;
}

.sub-type-list-table tr th:nth-child(4),
.sub-type-list-table tr td:nth-child(4) {
  width: 15%;
}

/* sahay application */

.sahay-application-container {
  max-width: calc(100vw - 60px);
}

.sahay-application-container .sahay-appli-table {
  min-width: 900px;
}

.sahay-appli-table tr th:nth-child(1),
.sahay-appli-table tr td:nth-child(1) {
  width: 15%;
}

.sahay-appli-table tr th:nth-child(2),
.sahay-appli-table tr td:nth-child(2) {
  /* width: 15%; */
  min-width: 97px;
  text-align: center;
}

.sahay-appli-table tr th:nth-child(3),
.sahay-appli-table tr td:nth-child(3) {
  width: 10%;
}

.sahay-appli-table tr th:nth-child(4),
.sahay-appli-table tr td:nth-child(4) {
  width: 15%;
}

.sahay-appli-table tr th:nth-child(5),
.sahay-appli-table tr td:nth-child(5) {
  width: 15%;
}

.sahay-appli-table tr th:nth-child(6),
.sahay-appli-table tr td:nth-child(6) {
  width: 15%;
}

.sahay-appli-table tr th:nth-child(7),
.sahay-appli-table tr td:nth-child(7) {
  width: 15%;
}

/* new member */

/* .new-member-container{
    max-width: calc(100vw - 60px);
} */

/* .new-member-table{
    min-width: 1140px;
}  */
.new-member-table.table-design-main {
  min-width: 1140px;
}

/* 
.new-member-table tr th:nth-child(1), .new-member-table tr td:nth-child(1) {
    width: 10%;
}
.new-member-table tr th:nth-child(2), .new-member-table tr td:nth-child(2) {
    width: 10%;
}
.new-member-table tr th:nth-child(3), .new-member-table tr td:nth-child(3) {
    width: 10%;
}
.new-member-table tr th:nth-child(4), .new-member-table tr td:nth-child(4) {
    width: 10%;
}
.new-member-table tr th:nth-child(5), .new-member-table tr td:nth-child(5) {
    width: 15%;
}
.new-member-table tr th:nth-child(6), .new-member-table tr td:nth-child(6) {
    width: 15%;
}
.new-member-table tr th:nth-child(7), .new-member-table tr td:nth-child(7) {
    width: 15%;
}
.new-member-table tr th:nth-child(8), .new-member-table tr td:nth-child(8) {
    width: 15%;
}

.new-member-table tr th:nth-child(9), .new-member-table tr td:nth-child(9) {
    width: 15%;
}

.new-member-table tr th:nth-child(10), .new-member-table tr td:nth-child(10) {
    width: 10%;
} */

/* bank management */

.bank-table-container {
  max-width: calc(100vw - 60px);
}

.bank-table-container .bank-table {
  min-width: 1200px;
}

.bank-table tr th:nth-child(1),
.bank-table tr td:nth-child(1) {
  width: 13%;
}

.bank-table tr th:nth-child(2),
.bank-table tr td:nth-child(2) {
  width: 15%;
}

.bank-table tr th:nth-child(3),
.bank-table tr td:nth-child(3) {
  width: 15%;
}

.bank-table tr th:nth-child(4),
.bank-table tr td:nth-child(4) {
  width: 15%;
}

.bank-table tr th:nth-child(5),
.bank-table tr td:nth-child(5) {
  width: 15%;
}

.bank-table tr th:nth-child(6),
.bank-table tr td:nth-child(6) {
  width: 15%;
}

/* bank management */

.year-container {
  max-width: calc(100vw - 60px);
}

.year-container .year-table {
  min-width: 510px;
}

.year-table tr th:nth-child(1),
.year-table tr td:nth-child(1) {
  width: 15%;
}

.year-table tr th:nth-child(2),
.year-table tr td:nth-child(2) {
  width: 15%;
}

.year-table tr th:nth-child(3),
.year-table tr td:nth-child(3) {
  width: 25%;
}

.year-table tr th:nth-child(4),
.year-table tr td:nth-child(4) {
  width: 15%;
}

/* user master management */

.user-master-container {
  max-width: calc(100vw - 60px);
}

.user-master-container .user-master-table {
  min-width: 1000px;
}


.user-master-table tr th:nth-child(1),
.user-master-table tr td:nth-child(1) {
  width: 10%;
}

.user-master-table tr th:nth-child(2),
.user-master-table tr td:nth-child(2) {
  width: 10%;
}

.user-master-table tr th:nth-child(3),
.user-master-table tr td:nth-child(3) {
  width: 10%;
}

.user-master-table tr th:nth-child(4),
.user-master-table tr td:nth-child(4) {
  width: 10%;
}

.user-master-table tr th:nth-child(5),
.user-master-table tr td:nth-child(5) {
  width: 10%;
}

.user-master-table tr th:nth-child(6),
.user-master-table tr td:nth-child(6) {
  width: 10%;
}

.user-master-table tr th:nth-child(7),
.user-master-table tr td:nth-child(7) {
  width: 15%;
}

.user-master-table-inner tr th,
.user-master-table-inner tr td {
  border-bottom: 0;
}

/* .user-master-table-inner tr th:first-child,
.user-master-table-inner tr td:first-child {
  border-radius: 0;
}

.user-master-table-inner tr th:last-child,
.user-master-table-inner tr td:last-child {
  border-radius: 0 !important;
} */
.family-report-table .table-view-more-btn {
  position: absolute;
  right: 12px;
  top: 20px;
  font-size: 12px;
  color: #122b45;
  font-family: "poppins-semibold";
  text-transform: capitalize;
}

.user-master-container .user-master-table-inner {
  min-width: 100%;
  border-spacing: 0 !important;
  margin-top: 7px;
}

.user-master-container .family-wise-table-inner {
  min-width: max-content;
}

.family-report-table tr th table th:nth-child(1),
.family-report-table tr td table td:nth-child(1) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(2),
.family-report-table tr td table td:nth-child(2) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(3),
.family-report-table tr td table td:nth-child(3) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(4),
.family-report-table tr td table td:nth-child(4) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(5),
.family-report-table tr td table td:nth-child(5) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(6),
.family-report-table tr td table td:nth-child(6) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(7),
.family-report-table tr td table td:nth-child(7) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(8),
.family-report-table tr td table td:nth-child(8) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(9),
.family-report-table tr td table td:nth-child(9) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(10),
.family-report-table tr td table td:nth-child(10) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(11),
.family-report-table tr td table td:nth-child(11) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(12),
.family-report-table tr td table td:nth-child(12) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(13),
.family-report-table tr td table td:nth-child(13) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(14),
.family-report-table tr td table td:nth-child(14) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(15),
.family-report-table tr td table td:nth-child(15) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(16),
.family-report-table tr td table td:nth-child(16) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(17),
.family-report-table tr td table td:nth-child(17) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(18),
.family-report-table tr td table td:nth-child(18) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(19),
.family-report-table tr td table td:nth-child(19) {
  width: 155px;
}

.family-report-table tr th table th:nth-child(20),
.family-report-table tr td table td:nth-child(20) {
  width: 155px;
}

.family-report-table tr td table td,
.family-report-table tr th table th {
  text-align: center;
}

/* .family-report-table .user-master-table-inner tr th:nth-child(1),
.family-report-table .user-master-table-inner tr td:nth-child(1) {
  width: 7%;
}

.family-report-table .user-master-table-inner tr th:nth-child(2),
.family-report-table .user-master-table-inner tr td:nth-child(2) {
  width: 7%;
}

.family-report-table .user-master-table-inner tr th:nth-child(3),
.family-report-table .user-master-table-inner tr td:nth-child(3) {
  width: 7%;
}

.family-report-table .user-master-table-inner tr th:nth-child(4),
.family-report-table .user-master-table-inner tr td:nth-child(4) {
  width: 7%;
}

.family-report-table .user-master-table-inner tr th:nth-child(5),
.family-report-table .user-master-table-inner tr td:nth-child(5) {
  width: 7%;
}

.family-report-table .user-master-table-inner tr th:nth-child(6),
.family-report-table .user-master-table-inner tr td:nth-child(6) {
  width: 7%;
}

.family-report-table .user-master-table-inner tr th:nth-child(7),
.family-report-table .user-master-table-inner tr td:nth-child(7) {
  width: 7%;
}

.family-report-table .user-master-table-inner tr th:nth-child(8),
.family-report-table .user-master-table-inner tr td:nth-child(8) {
  width: 7%;
}

.family-report-table .user-master-table-inner tr th:nth-child(9),
.family-report-table .user-master-table-inner tr td:nth-child(9) {
  width: 7%;
}
.family-report-table .user-master-table-inner tr th:nth-child(10),
.family-report-table .user-master-table-inner tr td:nth-child(10) {
  width: 7%;
} */

.new-member-top-spacing {
  margin-top: 45px !important;
}

.new-member-top-spacing-family {
  margin-top: 10px !important;
}

.custom-space-details {
  margin-top: 23px;
}

.export-btn-main .fil_Button,
.export-btn-main .fil_Button:hover {
  background: transparent;
  border: 2px solid #2c5bdf;
  font-size: 14px;
  color: #2c5bdf;
  text-transform: capitalize;
  font-family: "Poppins-Regular";
  padding: 6px 20px;
}

.export-btn-main .fil_Button_sample,
.export-btn-main .fil_Button_sample:hover {
  background: transparent;
  border: 2px solid #2c5bdf;
  font-size: 14px;
  color: #2c5bdf;
  text-transform: capitalize;
  font-family: "Poppins-Regular";
  padding: 6px 10px;
}

.ml-export {
  margin-left: 10px;
}

.plus-add {
  height: 17px !important;
  width: 17px !important;
  margin-right: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
  border-radius: 0px !important;
  -webkit-text-fill-color: #122b45;
}

.password-border-list.input-design-div .MuiFilledInput-underline,
.password-border-list.input-design-div .MuiFilledInput-underline:hover,
.password-border-list.input-design-div .MuiFilledInput-underline.Mui-focused {
  border-color: #cccccc;
}

.password-border-list.input-design-div.with-border input {
  border-right: 0px;
  border-radius: 4px 0px 0px 4px !important;
  -webkit-border-radius: 4px 0px 0px 4px !important;
  -moz-border-radius: 4px 0px 0px 4px !important;
  -ms-border-radius: 4px 0px 0px 4px !important;
  -o-border-radius: 4px 0px 0px 4px !important;
}

.text-end-details {
  justify-content: end;
  display: flex;
}

.user-profile-details {
  max-width: max-content;
  margin: auto;
  position: relative;
}

.user-edit-btn-details button,
.user-edit-btn-details button:hover {
  background: #eaac35 !important;
  max-width: max-content !important;
  padding: 4px !important;
  min-width: max-content !important;
  border-radius: 50% !important;
  height: 22px !important;
  width: 22px !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}

.user-edit-btn-details {
  position: absolute;
  right: 4px;
  bottom: 15px;
}

.edit-profile-user {
  height: 12px;
  width: 12px;
}

.status-deatils {
  color: #ffa800;
  font-size: 12px;
  line-height: 15px;
  font-family: "inter-regular";
}

.pending-status {
  color: #ffa800;
}

.approved-status {
  color: #188921;
}

.reject-status {
  color: red;
}

img.approve {
  height: 22px;
  width: 22px;
}

img.approve-disable {
  opacity: 0.5;
}

.approved-btn .approve-btn-list {
  padding: 0px 5px;
  min-width: max-content;
}

.approved-btn {
  max-width: max-content;
}

img.reject {
  height: 20px;
  width: 20px;
}

.reject-btn .reject-btn-list {
  padding: 0px 5px;
  min-width: max-content;
}

.reject-btn {
  max-width: max-content;
}

.flex-content-sahay {
  display: flex;
  align-items: center;
}

.max-content-ellipsis .MuiSelect-select {
  max-width: 140px !important;
}

.max-content-ellipsis .MuiInputBase-fullWidth {
  height: 30px;
}

.mobile-menu-hidden,
.desk-hidden-show {
  display: block;
}

.admin-border-radius {
  border-radius: 10px;
}

.admin-border-radius .user-apply-content {
  padding: 20px;
}

.res-mobile-show {
  display: none;
}

/* .mobile-menu-show{
    display: none;
} */

.mb-0px-sahay {
  margin-bottom: 0px !important;
}

.mt-0px-sahay {
  margin-top: 0px !important;
}

.admin-height-remove {
  max-height: initial;
  overflow: initial;
}

.flex-start-admin {
  justify-content: start;
}

.comman-btn-add-details,
.comman-btn-add-details:hover {
  /* border: 1px solid #eaac35; */
  background: transparent;
  /* color: #eaac35; */
  box-shadow: none !important;
  margin-bottom: 10px !important;
}

.hover-tooltip-custom {
  background: #eaac35;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  right: 0;
  z-index: 9;
  text-align: center;
  left: -30px;
  right: -25px;
  display: none;
  bottom: -27px;
}

.hover-tooltip-custom .title-tooltip {
  font-size: 10px;
  font-family: "poppins-semibold";
  line-height: 12px;
  color: #fff;
}

.mobile-menu-show-hover {
  position: relative;
}

.mobile-menu-show-hover:hover .no-text-decoration+.hover-tooltip-custom {
  display: block;
}

.user-flex-master {
  text-align: center;
}

.date-picker-details-content .MuiStack-root {
  padding-top: 0px;
  overflow: initial;
}

.date-picker-details-content .date-picker-inner {
  border: 1px solid #ccc;
  /* border-radius: 8px; */
  border-radius: 4px !important;
  background-color: #fff;
  font-family: "inter-regular";
}

.date-picker-details-content .date-picker-inner input {
  font-size: 14px;
  padding: 10px 10px;
}

.date-picker-details-content .date-picker-inner svg {
  height: 20px;
  display: flex;
}

.role-master-table .role-master-head tr th:nth-child(1),
.role-master-table .role-master-tbody tr td:nth-child(1) {
  width: 15.33%;
}

.role-master-table .role-master-head tr th:nth-child(2),
.role-master-table .role-master-tbody tr td:nth-child(2) {
  width: 15.33%;
}

.role-master-table .role-master-head tr th:nth-child(3),
.role-master-table .role-master-tbody tr td:nth-child(3) {
  width: 17.33%;
}

.role-master-table .role-master-head tr th:nth-child(4),
.role-master-table .role-master-tbody tr td:nth-child(4) {
  width: 15.33%;
}

.role-master-container {
  max-width: calc(100vw - 0px);
}

.role-master-container .role-master-table {
  min-width: 580px;
}

.mt-btn-spacing {
  margin-top: 22px;
}

.edit-drop-container .edit-drop-table {
  min-width: 800px;
}

.edit-drop-container {
  max-width: calc(100vw - 34px);
}

.mt-details-remove-pro {
  margin-top: 18px;
}

/* user profile update */
.user-profile-btn-main {
  display: flex;
  /* justify-content: space-between;
    align-items: center; */
}

.approve-plus {
  height: 17px;
  width: 17px;
}

.cursor-pointer {
  cursor: pointer;
  height: 20px;
  width: 20px;
  vertical-align: sub;
}

.modal {
  z-index: 999 !important;
}

.remark-textarea-details .MuiInputBase-formControl {
  padding: 10px;
  height: 150px;
  display: flex;
  align-items: start;
}

.custom-set-area {
  height: 75px;
}

.btn-submit-area .blue-button {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.set-textarea-box-top .textarea-details-user {
  height: 150px !important;
  border-radius: 4px;
  /* .sahay-application-tab-main{
    margin-bottom: 15px;
}
.sahay-application-tab-main .sahay-tab{
    box-sizing: border-box;
    border: 1px solid #4b93f4;
    background-color: #a6ccff;
    font-weight: bold;
} */
}

.admin-tabs-main .admin-tab {
  font-size: 13px;
  line-height: 16px;
  font-family: "Poppins-SemiBold";
  color: black;
  text-transform: capitalize;
  padding: 10px 15px;
  background-color: #a6ccff;
  border-radius: 4px;
  margin-right: 15px;
  min-height: 22px;
}

.admin-tabs-main .admin-tab:last-child {
  margin-right: 0px;
}

.admin-tabs-main .admin-tab.Mui-selected {
  color: white;
  background: transparent linear-gradient(180deg, #00a8ff 0%, #003d90 100%);
  /* background: transparent linear-gradient(180deg, #00a8ff 0%, #87fbff 100%); */
  /* background: #003d90; */
  border-radius: 4px;
}

/* .admin-tabs-main {
    margin-bottom: 5px;
  } */

.admin-tabs-main .MuiTabs-indicator {
  background-color: transparent;
  height: 0px;
}

.csv-button {
  float: right;
}

.approve-plus-csv {
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.role-table-main .MuiTableCell-root {
  padding: 0;
}

.MuiMenu-list {
  /* height: 300px; */
  max-height: 300px;
}

.flex-space-csv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.import-csv-btn label {
  display: flex;
  cursor: pointer;
}

.import-csv-btn .csv-button,
.import-csv-btn .csv-button:hover {
  background: #003d90;
  max-width: 135px;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  font-family: "poppins-regular";
  padding: 7px 20px;
  cursor: pointer;
  margin-right: 10px;
}

.import-csv-btn {
  width: 100%;
  cursor: pointer;
}

.user-apply-content .custom-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
}

.back-btn-box {
  display: flex;
}

.main-box-heading {
  display: flex;
  justify-content: space-between;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.small-circular-progress {
  width: 10px;
  /* Adjust the size as needed */
  height: 10px;
  /* Adjust the size as needed */
}

.sahay-multiple-main {
  margin-bottom: 15px;
}

.sahay-multiple-input-main .sahay-multiple-search {
  margin: 0px !important;
  width: 100% !important;
}

.multiple-download-btn .export-btn-main {
  margin: 0px;
}

.multiple-download-btn {
  display: flex;
  align-items: center;
}

.sahay-multiple-input-main .multiple-autocomplet-control input {
  padding: 0px;
}

.sahay-multiple-input-main .multiple-autocomplet-control .MuiOutlinedInput-root {
  padding: 0px 10px;
}

.sahay-multiple-input-main .multiple-autocomplet-control .MuiOutlinedInput-root input {
  padding: 6px;
}

.sahay-multiple-input-main .multiple-autocomplet-control .MuiFormLabel-root {
  top: -9px;
}

.multiple-amount-text {
  font-size: 15px;
  line-height: 19px;
  color: #122b45;
  font-family: "inter-medium";
  margin: 15px 0px;
}

.table-list .table-container {
  background-color: transparent;
}

.multiple-total-text-main .multiple-total-text {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  line-height: 19px;
  color: #122b45;
  font-family: "poppins-light";
  margin: 10px 0px 15px;
}

.multiple-total-text-main {
  border-bottom: 1px solid #d4d9e1;
}

.multiple-tablelist-table {
  min-width: 1000px;
}

.multiple-tablelist-table tr th:nth-child(1),
.multiple-tablelist-table tr td:nth-child(1) {
  width: 10%;
}

.multiple-tablelist-table tr th:nth-child(2),
.multiple-tablelist-table tr td:nth-child(2) {
  width: 15%;
}

.multiple-tablelist-table tr th:nth-child(3),
.multiple-tablelist-table tr td:nth-child(3) {
  width: 15%;
}

.multiple-tablelist-table tr th:nth-child(4),
.multiple-tablelist-table tr td:nth-child(4) {
  width: 10%;
}

.multiple-tablelist-table tr th:nth-child(5),
.multiple-tablelist-table tr td:nth-child(5) {
  width: 10%;
}

.multiple-tablelist-table tr th:nth-child(6),
.multiple-tablelist-table tr td:nth-child(6) {
  width: 10%;
}

.app-report-row {
  margin: 10px 0px 0px;
}

.escreport-table {}

.escreport-table tr th:nth-child(1),
.escreport-table tr td:nth-child(1) {
  width: 10%;
}

.escreport-table tr th:nth-child(2),
.escreport-table tr td:nth-child(2) {
  width: 12%;
}

.escreport-table tr th:nth-child(3),
.escreport-table tr td:nth-child(3) {
  width: 10%;
}

.escreport-table tr th:nth-child(4),
.escreport-table tr td:nth-child(4) {
  width: 10%;
}

.escreport-table tr th:nth-child(5),
.escreport-table tr td:nth-child(5) {
  width: 10%;
}

.escreport-table tr th:nth-child(6),
.escreport-table tr td:nth-child(6) {
  width: 10%;
}

.escreport-table tr th:nth-child(7),
.escreport-table tr td:nth-child(7) {
  width: 10%;
}

.escreport-table tr th:nth-child(8),
.escreport-table tr td:nth-child(8) {
  width: 10%;
}

.user-master-table-inner tr th,
.user-master-table-inner tr td {
  background: #fff;
  padding: 5px 15px !important;
}

.re-payment-table {
  min-width: 1200px;
}

.re-payment-table tr th:nth-child(1),
.re-payment-table tr td:nth-child(1) {
  width: 10%;
}

.re-payment-table tr th:nth-child(2),
.re-payment-table tr td:nth-child(2) {
  width: 10%;
}

.re-payment-table tr th:nth-child(3),
.re-payment-table tr td:nth-child(3) {
  width: 10%;
}

.re-payment-table tr th:nth-child(4),
.re-payment-table tr td:nth-child(4) {
  width: 10%;
}

.re-payment-table tr th:nth-child(5),
.re-payment-table tr td:nth-child(5) {
  width: 10%;
}

.re-payment-table tr th:nth-child(6),
.re-payment-table tr td:nth-child(6) {
  width: 10%;
}

.re-payment-table tr th:nth-child(7),
.re-payment-table tr td:nth-child(7) {
  width: 10%;
}

.family-report-table {
  min-width: 2200px;
  position: relative;
}

.family-report-table tr th:nth-child(1),
.family-report-table tr td:nth-child(1) {
  width: 5%;
}

.family-report-table tr th:nth-child(2),
.family-report-table tr td:nth-child(2) {
  width: 5%;
}

.family-report-table tr th:nth-child(3),
.family-report-table tr td:nth-child(3) {
  width: 5%;
}

.family-report-table tr th:nth-child(4),
.family-report-table tr td:nth-child(4) {
  width: 7%;
}

.family-report-table tr th:nth-child(5),
.family-report-table tr td:nth-child(5) {
  width: 5%;
}

.family-report-table tr th:nth-child(6),
.family-report-table tr td:nth-child(6) {
  width: 73%;
}

.commit-report-table {
  min-width: 1700px;
}

.commit-report-table tr th:nth-child(1),
.commit-report-table tr td:nth-child(1) {
  width: 7%;
}

.commit-report-table tr th:nth-child(2),
.commit-report-table tr td:nth-child(2) {
  width: 15%;
}

.commit-report-table tr th:nth-child(3),
.commit-report-table tr td:nth-child(3) {
  width: 10%;
}

.commit-report-table tr th:nth-child(4),
.commit-report-table tr td:nth-child(4) {
  width: 10%;
}

.commit-report-table tr th:nth-child(5),
.commit-report-table tr td:nth-child(5) {
  width: 10%;
}

.commit-report-table tr th:nth-child(6),
.commit-report-table tr td:nth-child(6) {
  width: 10%;
}

.commit-report-table tr th:nth-child(7),
.commit-report-table tr td:nth-child(7) {
  width: 10%;
}

.commit-report-table tr th:nth-child(8),
.commit-report-table tr td:nth-child(8) {
  width: 10%;
}

.commit-report-table tr th:nth-child(9),
.commit-report-table tr td:nth-child(9) {
  width: 10%;
}

.commit-report-table tr th:nth-child(10),
.commit-report-table tr td:nth-child(10) {
  width: 10%;
}

.commit-report-table tr th:nth-child(11),
.commit-report-table tr td:nth-child(11) {
  width: 10%;
}

.commit-report-table tr th:nth-child(12),
.commit-report-table tr td:nth-child(12) {
  width: 10%;
}

.commit-report-table tr th:nth-child(13),
.commit-report-table tr td:nth-child(13) {
  width: 10%;
}

.commit-report-table tr th:nth-child(14),
.commit-report-table tr td:nth-child(14) {
  width: 10%;
}

.apply-for-sahay-main-content {
  overflow: hidden;
}

.pagination-dropdown-box {
  max-width: 53px;
  width: 53px;
  margin-right: 5px;
}

.pagination-dropdown-box .pagination-select {
  padding: 2px 23px 2px 5px;
}

.pagination-dropdown-box .MuiSelect-select {
  padding: 0px !important;
  color: #6b7280;
  font-size: 13px;
  line-height: 19px;
  font-family: "inter-regular";
}

.pagination-dropdown-box .pagination-menuitem {
  padding: 0px;
}

.pagination-dropdown-box .form-control svg {
  position: absolute;
  right: 0px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.multiple-autocomplet-control .MuiFormControl-root {
  background-color: #fff;
}

.highlighted-row .MuiTableCell-root {
  /* background-color: #003d90; */
  background-color: #84b8fe57;
  color: black;
}

.disbursement-report-table {
  min-width: 1300px;
}

.disbursement-report-table tr th:nth-child(1),
.disbursement-report-table tr td:nth-child(1) {
  width: 5%;
}

.disbursement-report-table tr th:nth-child(2),
.disbursement-report-table tr td:nth-child(2) {
  width: 10%;
}

.disbursement-report-table tr th:nth-child(3),
.disbursement-report-table tr td:nth-child(3) {
  width: 9%;
}

.disbursement-report-table tr th:nth-child(4),
.disbursement-report-table tr td:nth-child(4) {
  width: 5%;
}

.disbursement-report-table tr th:nth-child(5),
.disbursement-report-table tr td:nth-child(5) {
  width: 10%;
}

.disbursement-report-table tr th:nth-child(6),
.disbursement-report-table tr td:nth-child(6) {
  width: 10%;
}

.disbursement-report-table tr th:nth-child(7),
.disbursement-report-table tr td:nth-child(7) {
  width: 10%;
}

.family-wise-report-table {
  min-width: 1200px;
}

.family-wise-report-table tr th:nth-child(1),
.family-wise-report-table tr td:nth-child(1) {
  width: 5%;
}

.family-wise-report-table tr th:nth-child(2),
.family-wise-report-table tr td:nth-child(2) {
  width: 10%;
}

.family-wise-report-table tr th:nth-child(3),
.family-wise-report-table tr td:nth-child(3) {
  width: 10%;
}

.family-wise-report-table tr th:nth-child(4),
.family-wise-report-table tr td:nth-child(4) {
  width: 10%;
}

.family-wise-report-table tr th:nth-child(5),
.family-wise-report-table tr td:nth-child(5) {
  width: 10%;
}

.family-wise-report-table tr th:nth-child(6),
.family-wise-report-table tr td:nth-child(6) {
  width: 10%;
}

.family-wise-report-table tr th:nth-child(7),
.family-wise-report-table tr td:nth-child(7) {
  width: 10%;
}

.background_loader .MuiDialog-container .MuiPaper-root {
  box-shadow: none;
  background-color: transparent;
  margin: 0;
}

.pending-button-sahay {
  margin-right: 15px;
}

.display-row-add-user .add-check-flex-doc {
  max-width: 100%;
  flex: 0 0 100%;
}

.add-check-flex-doc .add-role-flex {
  width: 100%;
}

.mt-select-doc {
  margin-top: 10px;
}

/* Moholla filter */
.moholla-filter-main .multiple-autocomplet-control .MuiOutlinedInput-root {
  padding: 3px 10px;
}

/* admin dashboard css */

/* .main-dashboard {
  padding: 20px 30px;
  background-color: #f0f4f9;
} */

.admin-dashboard-inner-content {
  display: flex;
  max-width: 400%;
  width: 100%;
  align-items: center;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #f0f4f9;
}

.dashboard-card-image,
.dashboard-card-image-2,
.dashboard-card-image-3,
.dashboard-card-image-4,
.dashboard-card-image-5,
.dashboard-card-image-6 {
  align-items: center;
  background-color: #fccaf2;
  border-radius: 4px;
  display: flex;
  height: 55px;
  justify-content: center;
  margin-right: 30px;
  width: 55px;
}

.dashboard-card-image-2 {
  background-color: #cafcd9;
}

.dashboard-card-image-3 {
  background-color: #f6f1a8;
}

.dashboard-card-image-4 {
  background-color: #dbcafc;
}

.dashboard-card-image-5 {
  background-color: #fce1ca;
}

.dashboard-card-image-6 {
  background-color: #85d0ee;
}

.dashboard-card-image img,
.dashboard-card-image-2 img,
.dashboard-card-image-3 img,
.dashboard-card-image-4 img,
.dashboard-card-image-5 img,
.dashboard-card-image-6 img {
  max-width: 35px;
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.main-search-date {
  display: flex;
  align-items: center;
}

.input-design-div .cus-date-picker div {
  height: 35px;
}

/* .input-design-div .cus-date-picker div input,
.input-design-div {
  height: 35px;
} */

.cus-user-master-table tr th:nth-child(1),
.cus-user-master-table tr td:nth-child(1) {
  width: 6%;
}

.cus-user-master-table tr th:nth-child(2),
.cus-user-master-table tr td:nth-child(2) {
  width: 20%;
}

.cus-user-master-table tr th:nth-child(3),
.cus-user-master-table tr td:nth-child(3) {
  width: 8%;
}

.cus-user-master-table tr th:nth-child(4),
.cus-user-master-table tr td:nth-child(4) {
  width: 12%;
}

.user-master-table tr th:nth-child(5),
.user-master-table tr td:nth-child(5) {
  width: 10%;
  text-align: center;
}

.table-design-main .flex-program {
  display: flex;
}

.scroll-table::-webkit-scrollbar {
  width: 0px;
}

.user-sahay-td .swap-icon {
  padding: 0px;
}

.sahay-appli-head .sahay-appli-tr {
  background-color: #a6ccff;
}

.sahay-appli-tr .user-sahay-td {
  /* display: flex; */
  align-items: center;
}

/* rakesh css start  */
.common-table-container-custom thead tr {
  background-color: #a6ccff;
}

.common-table-container-custom {
  border-radius: 10px;
}

.custom-date-picker .input-design-div.with-border input {
  background-color: transparent;
  border: transparent;
  font-size: 12px;
}

.cus-date-picker .MuiInputBase-root.Mui-focused fieldset {
  border: 1px solid #d4d9e1;
}

.custom-date-picker .input-design-div.with-border input::placeholder {
  font-size: 12px;
}

.user-sahay-td-svg {
  position: relative;
  top: 6px;
}

.custom-date-picker .input-design-div.with-border .MuiStack-root {
  padding-top: 14px;
}

.sahay-multiple-input-main .MuiStack-root {
  padding-top: 0;
  overflow: hidden;
}

.common-date-picker-custom label {
  font-size: 12px;
  line-height: 14px;
}

.common-date-picker-custom input {
  padding: 8px 10px;
  font-size: 12px;
}

.common-date-picker-column .sahay-multiple-input-main .MuiStack-root {
  padding-top: 5px;
  overflow: hidden;
}

/* rakesh css end  */

.donner-name {
  background-color: white;
  border: 1px solid lightgray;
  min-height: 30px;
  max-height: calc(100vh - 545px);
  padding: 3px 10px;
  border-radius: 4px;
  overflow: auto;
}

.donner-name p {
  font-size: 13px;
  line-height: 25px;
  font-family: "inter-medium";
}

.custom-res-set-search-mb {
  margin-bottom: 15px;
  justify-content: end;
}

.custom-input-dibursement {
  max-width: 250px;
}

.custom-res-set-search-box {
  display: flex;
  align-items: center;
  gap: 12px;
}

.add-bill-table-custom tr th:nth-child(1),
.add-bill-table-custom tr td:nth-child(1) {
  width: 5%;
}

.add-bill-table-custom tr th:nth-child(2) {
  width: 15%;
}

.custom-bills-csv-box {
  display: flex;
  justify-content: end;
  align-items: end;
  gap: 15px;
}

.user-basic-custom-line {
  border-bottom: 1px solid #000;
  margin-bottom: 25px;
}

.user-basic-custom-line .multiple-doc .select-view-document-name {
  margin-top: 0;
}

.user-sahay-tbody .highlight-save-sahay td {
  background: #eccb8d;
}

.FID-back {
  display: flex;
}


.adjust-list-table tr th:nth-child(1),
.adjust-list-table tr td:nth-child(1) {
  width: 20%;
}

.adjust-list-table tr th:nth-child(2),
.adjust-list-table tr td:nth-child(2) {
  width: 20%;
}

.adjust-list-table tr th:nth-child(3),
.adjust-list-table tr td:nth-child(3) {
  width: 20%;
}

.adjust-list-table tr th:nth-child(4),
.adjust-list-table tr td:nth-child(4) {
  width: 30%;
}

.adjust-list-table tr th:nth-child(5),
.adjust-list-table tr td:nth-child(5) {
  width: 30%;
}

.user-name {
  color: #003d90;
}


.table-container .user-master-table-heading {
  min-width: 1800px;
}

/* 
.user-master-table-heading-second:first-child{
  width: 4%;
}

.user-master-table-heading-second:nth-child(2){
  width: 5%;
} */

.user-master-table-heading-second:nth-child(7){
  width: 5%;
} 
.user-master-table-heading-second:nth-child(8){
  width: 5%;
} 