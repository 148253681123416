
@font-face {
    font-family: 'inter-bold';
   
    src: url(../../../../assets/fonts/inter-bold.ttf);
}

/* .main-login {

    background-image: url('../../../../assets/images/png/backlogin.png');
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    background-size: cover;
}

.white-login-main {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.white-login-box.MuiBox-root.css-0 {
    background: #fff;
    padding: 50px;
    border-radius: 15px;
}
.logo-set2 {
    margin-bottom: 10px;
}

.main-box {
    max-width: 300px !important;
    width: 300px;
    flex: 0 0 300px;

}

.box-text p {
    color: white;
    font-size: 32px;
    line-height: 39px;
    font-family: 'inter-bold';
}

.bluebox-text p {
    color: #122B45;
}

.box-login-text p {
    font-family: 'inter-regular';
    font-size: 16px;
    color: white;
    line-height: 20px;
    margin: 15px 0px 40px 0px;
} */
.otp-verification-input {
    margin: 0px 10px;
    width: 60px !important;
    height: 60px !important;
    border-radius: 5px;
    border: 1px solid #c6c6c6;
    font-size: 20px;
    font-weight: 500;
}

.otp-verification-input-wrap {
    justify-content: center;
}

.resend-otp-main{
display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}


.resend-otp  .resend-otp-btn {
   
    background:none;

    border: none;

    /* padding: 5px; */
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;

    /* color: #fff; */
    color: black;
        font-size: 15px;
        line-height: 20px;
        border-radius: 4px;
        min-height: 30px;
    
        font-family: 'inter-regular';
        text-transform: capitalize;
        letter-spacing: 0.5px;
        min-width: 95px;
        /* box-shadow: 0px 3px 4px #ccc4b3 */
    
}

.resend-otp .resend-otp-btn:hover {
    /* background-color: #519af9; */

        background: none;
}